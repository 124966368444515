<template>
  <header class="section panel panel_landing" id="panel_landing">
    <div class="container">
      <div class="logo logo_mobile">
        <img src="@/assets/logo.svg" alt="University of East London" />
      </div>
      <div class="logo logo_desktop">
        <img src="@/assets/logo_desktop.svg" alt="University of East London" />
      </div>

      <h1 class="site_desc" title="Pioneering Futures since 1898">
        Pioneering Futures<br />
        since 1898
      </h1>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelLanding",
  props: {
    msg: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
});
</script>
