<template>
  <section class="section panel panel_basedin" id="panel_basedin">
    <div class="container">
      <div class="header_text">
        <div class="basedat">You’ll be based at</div>
        <div class="location">{{ name }}</div>
      </div>
      <div class="image_wrapper">
        <div class="img1 campus_1">
          <img
            v-if="img"
            :src="
              require('@/assets/img/results-day/' +
                img +
                '/' +
                img +
                '-campus-1.jpg')
            "
            :alt="`${name} image 1`"
          />
        </div>
        <div class="img2 campus_2">
          <img
            v-if="img"
            :src="
              require('@/assets/img/results-day/' +
                img +
                '/' +
                img +
                '-campus-2.jpg')
            "
            :alt="`${name} image 2`"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "PanelBasedIn",
  props: {
    name: String,
    img: String,
  },

  mounted() {},
  methods: {},
});
</script>
